/*================================================================================
	Item Name: Vuexy - Vuejs,	HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
#root {
   background: "white";
}
.tableview-description td {
   font-size: 12px !important;
}

* {
   font-family: "Inter", sans-serif;
   // color: #000000
}

body {
   color: #000000;
}

.expanded span.brand-logo {
   /* background: red; */
   transition: 0.2s;
   translate: 100%;
   scale: 2;
}

.menu-open span.brand-logo {
   /* background: red; */
   translate: 100%;
   scale: 2;
   transition: 0.2s;
}

.header700-1 {
   font-weight: 700;
   font-size: 1.5rem;
   line-height: 1.8125rem;
}

.header700-2 {
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1.3125rem;
}

.eat-btn {
   background: linear-gradient(166.45deg, #f2473f -14.38%, #ff6b34 105%);
   color: #ffffff;
   border: #f2473f;
   border-radius: 5px;
}

.btn-size-1 {
   font-weight: 500;
   font-size: 0.6875;
   line-height: 0.8125rem;
   text-align: center;
   letter-spacing: 0.0229166875rem;
   padding: 0.5rem 1.1rem;
}

.outlin-btn {
   border: 1px solid gray !important;
   color: gray !important;
}

.eats-btn2 {
   border-radius: 5px;
   border: 1px solid #832a0e !important;
   color: #832a0e;
}

.auth-logo {
   text-align: center;
   padding-bottom: 109px;
   padding-top: 20px;
   // textAlign:"center", paddingBottom: '109px', paddingTop: '20px'
}

@media (max-width: 575px) {
   .auth-logo {
      text-align: center;
      padding-bottom: 0;
      padding-top: 20px;
      // textAlign:"center", paddingBottom: '109px', paddingTop: '20px'
   }
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
   font-family: "Inter", sans-serif;
   font-weight: 500;
   line-height: 1.2;
   color: #000000;
}

.label1 {
   // display: flex;
   // justify-content: space-between;
   font-weight: 400;
   font-size: 12px;
   line-height: 14px;
   color: #a1b3c9;
}

// h1{
// 	color:'#000000'
// }

.custom-avtar .upload-image {
   display: none;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
   width: 100%;
   height: 100%;
   gap: 0.35rem;
   z-index: 10;
}

.custom-avtar:hover .upload-image {
   display: flex;
}

.upload-image p {
   font-weight: 500;
   font-size: 0.625rem;
   line-height: 1.5rem;
   color: #ffffff;
}

.eat-btn-sweetalert {
   background: #01a879;
   color: #ffffff;
   border: #01a879;
   border-radius: 5px;
   height: 3em;
   width: 4em;
}

.title-sweetalert {
   color: #01a879 !important;
}

.eat-btn:hover {
   box-shadow: #f2473f !important;
}

.tooltip-inner {
   background: #ffffff;
   box-shadow: 0px 4px 4px rgba(181, 181, 181, 0.25);
   // transform: matrix(1, 0, 0, -1, 0, 0);
}

.tooltip-arrow::before {
   border-top-color: #fcf9f9 !important;
}

.tooltip-inner ul {
   list-style: none;
   text-align: left;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled):active {
   background: linear-gradient(166.45deg, #f2473f -14.38%, #ff6b34 105%) !important;
   color: white !important;
   border: 1px solid transparent !important;
}

// .btn-outline-secondary:not(:disabled):not(.disabled).active, [dir] .btn-outline-secondary:not(:disabled):not(.disabled):focus {
//     background-color: transparent !important;
// 	color: gray !important;
// }
.import-export-dropdown {
   color: #01a879 !important;
   border: 1px solid #01a879 !important;
   background-color: transparent;
}

.sat-card {
   min-width: "205px";
   // height: '100px';
   overflow: hidden;
}

.form-check-input:checked {
   background-color: #ff6b34;
   border-color: #f2473f;
   box-shadow: 0px 2px 4px rgba(115, 103, 240, 0.4);
}

.form-check-input {
   cursor: pointer;
}

.dropdown-toggle::after {
   content: none;
}

// .css-m2o6ej-multiValue {
//     background-color: rgba(0, 0, 0, 0.12);
//     border-radius: 12px;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     margin: 2px;
//     min-width: 0;
//     max-width: fit-content;
//     box-sizing: border-box;
// }
// .css-1rhbuit-multiValue,
// .css-g1d714-ValueContainer div,
// .css-k3ockt-control div{
// 	width: auto !important;
// 	border-radius: 10px;
// 	    max-width: max-content;
// }

.css-1okebmr-indicatorSeparator {
   display: none;
}

.color-danger path,
.color-danger .css-14el2xx-placeholder,
.color-danger .css-1s2u09g-control {
   border-color: #f2473f !important;
   color: #f2473f;
   fill: #f2473f;
}

.dropdown-menu[data-popper-placement]:not([data-popper-pla]) {
   width: 100%;
}

.dropdown-menu .dropdown-item {
   width: 100%;
}

.cooking-status-tag {
   color: var(--alert-info, #44c4fd);
   font-size: 0.875rem;
   font-weight: 600;
   padding: 0.2rem 1rem;
   padding-right: 0.3rem;
   border-radius: 20px;
   width: max-content;
}

.eatslogo {
   width: 56.89px;
   height: 19px;
   font-weight: 700;
   font-size: 25.1305px;
   line-height: 30px;

   background: linear-gradient(180.61deg, #5e3225 7.84%, #832a0e 91.39%);
   -webkit-background-clip: text !important;
   -webkit-text-fill-color: transparent !important;
   background-clip: text !important;
}

.strong {
   font-family: "Inter";
   font-style: normal;
   font-weight: 600;
   font-size: 0.75rem;
   line-height: 1.3125;
   color: #292d32;
}

.soft {
   font-family: "Inter";
   font-style: normal;
   font-weight: 500;
   font-size: 0.875rem;
   line-height: 1.4375rem;
   color: #a1acba;
   word-wrap: break-word;
}

.no-wrap {
   white-space: nowrap;
}

.disabled-div {
   background-color: #efefef;
}

// ================ Data Table====================
// .rdt_Table {
// 	min-height: 200px !important;
// }

.rdt_TableHeadRow div {
   // text-overflow:clip;
   white-space: break-spaces;
   // text-align: left;
   padding: 0 0.3rem;
   // background: red !important;
}

.table-header-title {
   color: #1a264e;
   font-size: 1.3rem;
   white-space: nowrap;
   font-weight: 700;
   text-align: center;
   margin: 0;
}

.rdt_TableCol {
   gap: 8rem;
}

.prev,
.next {
   cursor: pointer;
}

// ======================================
// .stat {
// 	display: flex !important;
// 	justify-content: space-between !important;
// }

.success-btn {
   width: fit-content;
   margin: 10px auto;
}

// @media (width < 950px) {

// 	.stat {
// 		display: block !important;
// 		justify-content: space-between !important;
// 	}

// 	.stat div {
// 		margin-bottom: 10px !important;
// 	}
// }

.refreshing {
   cursor: pointer;
   animation: rot 1s linear infinite forwards;
}

// Styling table
.table-bottom {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   align-items: center;
}

.table-bottom ul {
   margin: 0;
}

.sc-dmRaPn {
   height: 100% !important;
   background: #ffffff;
}

.sc-dmRaPn::-webkit-scrollbar {
   width: 0;
}

@keyframes rot {
   from {
      rotate: (0deg);
   }

   to {
      rotate: (360deg);
   }
}

.truncate {
   width: 250px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

//   order-complete-modal

.order-complete-modal .label {
   display: flex;
   justify-content: space-between;
   font-weight: 400;
   font-size: 16px;
   line-height: 18px;
   color: #a1b3c9;
}

.order-complete-modal .rad {
   font-weight: 400;
   font-size: 14px;
   line-height: 21px;
   color: #6e6b7b;
}

.order-complete-modal .amount {
   font-weight: 600;
   font-size: 18px;
   line-height: 18px;
   color: #28385f;
}

.payment-status-check {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.payment-status-check input {
   margin: 0 0.5rem;
}

::-webkit-scrollbar {
   width: 0.5em;
   height: 0.5rem;
}

::-webkit-scrollbar-track {
   background: rgb(240, 239, 239);
   border-radius: 10px;
}

::-webkit-scrollbar-thumb {
   background: rgba(165, 161, 161, 0.479);
   border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
   background: #ff6a348a;
}

// imported

.rst-table {
   border-radius: 10px;
   // aspect-ratio:1 / 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 0.5rem;
   min-width: 104px;
   height: 83px;
   cursor: pointer;
   overflow: hidden;

   font-family: "Inter";
   // font-style: normal;
   font-weight: 400;
   font-size: 16px;
   // line-height: 35px;
}

.bookedtable {
   background: linear-gradient(166.45deg, #f2473f -14.38%, #ff6b34 105%);
   color: #edf5f9;
}

.unbookedtable {
   border: 2px dashed #bfd1e3;
   color: #1a264e;
}

.clubtable {
   background: #ffebe6;
}

.table-no {
   display: flex;
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
}

.table-no .tn {
   font-weight: 500;
   font-size: 16px;
   line-height: 18px;
}

.club div {
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
}

.club span {
   font-weight: 500;
   font-size: 14px;
   line-height: 18px;
}

.table-bottom {
   display: flex;
   justify-content: space-evenly;
   align-items: center;
}

.table-bottom ul {
   margin: 0;
}

@media (max-width: 500px) {
   .rst-table {
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
   }

   .table-no .tn {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
   }

   .club {
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
   }

   .rst-table p {
      margin: 0;
   }
}

.filled-star {
   fill: #ffb956;
   border: transparent;
}

.unfilled-star {
   fill: #e7ecf0;
   border: transparent;
}

.order-btn-group {
   display: flex;
   flex-wrap: nowrap;
   flex-grow: 1;
}

.order-btn-group .btn-secondary {
   font-weight: 500;
   font-size: 11px;
   line-height: 13px;
   text-align: center;
   letter-spacing: 0.366667px;
   padding: 9px 10px;
   border: none;
   background: linear-gradient(166.45deg, rgba(242, 71, 63, 0.1) -14.38%, rgba(255, 107, 52, 0.1) 105%) !important;
   color: #702913 !important;
}

.order-btn-group .btn-secondary.active {
   background-color: #702913 !important;
   color: #ffffff !important;
}

.order-btn-group .btn-secondary.active:focus {
   background-color: #702913 !important;
}

.card {
   box-shadow: 0px 4px 24px rgb(0 0 0 / 6%);
}

.menu-item-list {
   padding: 0 1rem;

   .category-title {
      font-weight: 700;
      font-size: 1.0625rem;
      color: rgb(7, 9, 17);
      padding: 1rem;
      text-transform: capitalize;
   }

   .menu-item-container {
      display: grid;
      gap: 1rem;
      box-shadow: none;
   }

   .menu-item-container2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      box-shadow: none;
   }

   .menu-item-container3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      box-shadow: none;
   }

   .menu-item-card {
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid var(--text-gray-200, #e7ecf0);
      margin: 0;
   }
}

// ------------------------  pdfInput ----------
.input-pdf:hover {
   box-shadow: 0px 0px 1px 1px #f2473f;
}

.input-pdf div:active {
   background-color: rgba(128, 128, 128, 0.142);
}

.input-group-text:active {
   background-color: rgba(128, 128, 128, 0.079);
   box-shadow: 0px 0px 1px 1px green;
}

// ===================== table-view =================
.table-view {
   display: flex;
   flex-direction: column;
   height: calc(100dvh - 160px);
   max-height: calc(100dvh - 140px);
   box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
}

.table-view-body {
   display: grid;
   gap: 12px;
   grid-template-columns: 225px auto;
   flex-grow: 1;
   overflow: hidden;
   // height: calc(100vh - 200px);
   // max-height: calc(100vh - 180px)
}

.table-view-body .order-details {
   display: grid;
   grid-template-columns: auto 19.1875rem;
   flex-grow: 1;
   overflow: hidden;
}

.table-view-body > div {
   overflow: hidden;
   width: 100%;
}

.table-view-body .card {
   margin: 0;
}

.table-group {
   display: grid;
   overflow-y: overlay;
   overflow-x: hidden;
   grid-template-columns: 50% 50%;
   row-gap: 3px;
   padding: 0 4px;
}

.live-order-footer {
   flex: 0 0 auto;
   display: flex;
   justify-content: space-between;
   width: -webkit-fill-available;
   align-items: center;
}

.kitchen-queue-head {
   flex-grow: 0;
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: space-between;

   color: var(--text-gray-800, #28385f);
   font-size: 1.125rem;
   font-weight: 600;
   line-height: 131.187%;
}

.oreder-header-toggler {
   cursor: pointer;
   display: none;
}

.order-header {
   background: white;
   z-index: 100;
}

.show .dropdown-chew {
   rotate: -90deg;
}

@media (width > 700px) {
   .profile-view {
      display: grid;
      gap: 10px;
      grid-template-columns: 356px auto;
   }
}

.table-merged {
   display: flex;
   font-weight: 500;
   font-size: 0.75rem;
   line-height: 1.3125rem;
   color: #5b6d8e;
   align-items: center;
   gap: 3px;
   max-width: fit-content;
   background: #f5f8fa;
   border-radius: 9999px;
   padding-left: 9px;
   padding-right: 6px;
}

.table-merged button {
   border: 0.1px solid transparent;
   background-color: transparent;
   color: #5b6d8e;
   font-weight: 500;
   font-size: 0.75rem;
   line-height: 1.3125rem;
   border-radius: 50%;
}

@media (max-width: 720px) {
   .table-view-body {
      display: grid;
      gap: 12px;
      grid-template-rows: max-content 1fr !important;
      grid-template-columns: auto;
      height: calc(100vh - 160px);
      max-height: calc(100vh - 140px);
   }

   .table-group {
      display: flex;
      flex-wrap: no-wrap;
      flex-grow: 1;
      overflow-y: hidden;
      overflow-x: auto;
      column-gap: 3px;
      padding: 0 4px;
      padding-bottom: 10px;
   }

   .live-order-footer {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      width: -webkit-fill-available;
      align-items: center;
      flex-direction: column;
      padding: 0 0.1rem;
   }

   .live-order-footer > div {
      width: 100%;
      justify-content: space-between;

      .add-item {
         width: 100%;
      }
   }

   .rst-table {
      height: 83px;
   }

   .live-order-refresher,
   .kitchen-queue-head {
      display: none !important;
   }

   .oreder-header-toggler {
      display: block;
   }

   .order-header {
      display: none;
      position: absolute;
      width: max-content;
      left: 2rem;
      box-shadow: 0px 0px 3px 0px;
   }
}

@media (width > 700px) {
   .profile-view {
      display: grid;
      gap: 10px;
      grid-template-columns: 356px auto;
   }
}

//?????????????????????????????????????????????????????????
// ------------------------  pdfInput ----------
.input-pdf:hover {
   box-shadow: 0px 0px 1px 1px #f2473f;
}

.input-pdf div:active {
   background-color: rgba(128, 128, 128, 0.142);
}

.input-group-text:active {
   background-color: rgba(128, 128, 128, 0.079);
   box-shadow: 0px 0px 1px 1px green;
}

.search-input {
   position: relative;
   width: 300px;
   margin-right: auto;
}

.search-input input {
   padding-left: 30px;
}

.search-icon svg {
   display: block;
   position: absolute;
   // border: 2px solid blue;
   top: 0;
   opacity: 0.7;
   margin-left: 5px;
   margin-top: 7px;
   scale: 0.8;
}

// ____________________ import Export _________________

.import-model-wrapper {
   // width: 300px;
   position: absolute;
   bottom: 0;
   right: 0;
   margin-bottom: 13vh;
   margin-right: 2vw;
   box-sizing: border-box;
   width: 388px;
   height: fit-content;
   background: #ffffff;
   border: 1px solid #ffeee8;
   // box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.1);
   border-radius: 16px;
   overflow: hidden;
   /* Auto layout */
}

.import-model-mainarea {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding: 24px 20px;
   font-family: "Inter";
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   // line-height: 160%;
   text-align: center;
   color: #616161;
}

.import-model-wrapper h2 {
   font-family: "Inter";
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 29px;
   color: #002b51;
}

.import-model-head {
   display: flex;
   width: 100%;
   color: #002b51;
   background: #ffffff;
   padding: 10px 20px 5px 10px;
   justify-content: space-between;
   flex-direction: row;
   border-bottom: 1px solid #eeeff0;
}

.import-model-head i {
   border: 0.1rem solid rgba(165, 152, 152, 0.563);
   padding: 1.5px;
   border-radius: 50%;
   color: #97aac2;
   cursor: pointer;
   height: fit-content;
}

.import-model-body {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 10px 5px;
   text-align: center;
}

.import-model-dragarea {
   overflow: hidden;
   width: 100%;
   outline: 7px dashed white;
   outline-offset: -3px;
   border: 2px solid #702913;
   height: 203px;
   padding: 20px 0;
   border-radius: 16px;
   color: black;
   font-weight: 500;
   font-size: 12px;
   line-height: 18px;
}

.import-model-body2 {
   width: 100%;
   margin-top: 10px;
}

.importing-exporting {
   display: flex;
   width: 94%;
   justify-content: space-between;
   align-items: center;
   margin: 5px;
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
}

.importing-exporting p {
   margin: 0 0 5px 0;
   text-align: start;
}

.import-model-progressbar {
   width: 70%;
}

.export-file-logo {
   position: absolute;
   right: 0;
   z-index: 1400;
   bottom: -72vh;
   cursor: pointer;
}

.order-item-addons-active {
   background-image: linear-gradient(180.63deg, #5e3225 3.63%, #832a0e 93.58%);
   // text-fill-color: transparent;
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.order-item-addons-active svg {
   stroke: #832a0e;
}

.version {
   display: none;
}

.expanded .version {
   display: contents;
}

.pill-active,
.pill {
   background: white;
   color: #b9b9c3;
   border: 0.5px solid #b9b9c3;
   padding: 0.2rem 1rem;
   border-radius: 20px;
   width: max-content;
   margin: 2px;
   cursor: pointer;
}

.pill-active {
   background: linear-gradient(166.45deg, #f2473f -14.38%, #ff6b34 105%);
   color: white;
   border: 0.5px solid #f2473f;
}

.cart {
   height: inherit;

   margin: 0 5px;
}

.cart .header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.cart .item-name {
   color: var(--text-gray-900, #1a264e);
   font-size: 0.875rem;
   font-family: Inter;
   font-weight: 600;
   line-height: 1rem;
   margin-bottom: 0.8rem;
}

.cart .varient-name {
   color: #292d32;
   font-size: 0.75rem;
   font-family: Inter;
   font-weight: 600;
   line-height: 1.3125rem;
   margin: 0;
}

.cart .addon-label {
   color: var(--text-gray-600, #5b6d8e);
   text-align: center;
   font-size: 0.75rem;
   font-family: Inter;
   font-weight: 500;
   line-height: 1.3125rem;
   border-radius: 9999px;
   background: #f5f8fa;
   padding: 0.125rem 0.375rem;
}

// . {
//   color: #01a879;
//   text-align: left;
//   font: 600 14px/16px "Inter", sans-serif;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }
.variants-addons {
   display: flex;
   flex-direction: row;
   gap: 4px;
   align-items: center;
   justify-content: flex-start;
   flex-shrink: 0;
   position: relative;
   color: #01a879;
   text-align: center;
   font: 600 12px "Inter", sans-serif;
   position: relative;
   cursor: pointer;
}

.expandable-content {
   .title {
      color: var(--text-gray-500, #768da9);
      font-size: 0.75rem;
      font-family: Inter;
      font-weight: 600;
      letter-spacing: 0.0625rem;
      text-transform: uppercase;
   }
}

// ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;

/* From uiverse.io by @satyamchaudharydev */
/* removing default style of button */

.search button {
   border: none;
   background: none;
   color: #8b8ba7;
}

/* styling of whole input container */
.search {
   --timing: 0.3s;
   --width-of-input: 55%;
   --height-of-input: 40px;
   --border-height: 2px;
   --input-bg: #fff;
   --border-color: #2f2ee9;
   // --border-radius: 30px;
   --after-border-radius: 1px;
   position: relative;
   width: var(--width-of-input);
   height: var(--height-of-input);
   display: flex;
   align-items: center;
   padding-inline: 0.8em;
   // border-radius: var(--border-radius);
   transition: border-radius 0.5s ease;
   background: var(--input-bg, #fff);
   box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
}
@media (max-width: 720px) {
   .search {
      width: 100% !important;
   }
}

/* styling of Input */
.input {
   font-size: 0.9rem;
   background-color: transparent;
   width: 100%;
   height: 100%;
   padding-inline: 0.5em;
   padding-block: 0.7em;
   border: none;
}

/* styling of animated border */
.search:before {
   content: "";
   position: absolute;
   background: var(--border-color);
   transform: scaleX(0);
   transform-origin: center;
   width: 100%;
   height: var(--border-height);
   left: 0;
   bottom: 0;
   border-radius: 1px;
   transition: transform var(--timing) ease;
}

/* Hover on Input */
.search:focus-within {
   border-radius: var(--after-border-radius);
}

input:focus {
   outline: none;
}

/* here is code of animated border */
.form:focus-within:before {
   transform: scale(1);
}

/* styling of close button */
/* == you can click the close button to remove text == */
.reset {
   border: none;
   background: none;
   opacity: 0;
   visibility: hidden;
}

/* close button shown when typing */
input:not(:placeholder-shown) ~ .reset {
   opacity: 1;
   visibility: visible;
}

/* sizing svg icons */
.search svg {
   width: 17px;
   margin-top: 3px;
}

@media (width>750px) {
   .custum-table-fix .rdt_TableHeadRow .rdt_TableCol:last-child {
      position: sticky;
      right: 0;
      background: inherit;
      z-index: 0;
   }

   .custum-table-fix .rdt_TableCell:last-child {
      position: sticky;
      right: 0;
      background: inherit;
      z-index: 0;
   }

   .custum-table-fix .rdt_TableRow:hover .rdt_TableCell:last-child {
      color: rgba(0, 0, 0, 0.87);
      background-color: inherit;
      // -webkit-transition-duration: 0.15s;
      // transition-duration: 0.15s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      border-bottom-color: #ffffff;
      outline-style: solid;
      outline-width: 1px;
      outline-color: #ffffff;
   }

   .custum-table-fix .rdt_TableCell:last-child:has(.show) {
      z-index: 10;
   }

   .custum-table-fix .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2) {
      position: sticky;
      right: 100px;
      background: inherit;
   }

   .custum-table-fix .rdt_TableCell:nth-last-child(2) {
      position: sticky;
      right: 100px;
      background: inherit;
   }

   .custum-table-fix .rdt_TableRow:hover .rdt_TableCell:nth-last-child(2) {
      color: rgba(0, 0, 0, 0.87);
      background-color: inherit;
      // -webkit-transition-duration: 0.15s;
      // transition-duration: 0.15s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      border-bottom-color: #ffffff;
      outline-style: solid;
      outline-width: 1px;
      outline-color: #ffffff;
   }
}

.css-1pahdxg-control {
   border-color: #f2473f !important;
   box-shadow: 0 0 0 0px #f2473f !important;
}

.recipt {
   * {
      font-family: "Fira Code", monospace !important;
   }

   p {
      margin: 0;
   }

   table {
      * {
         color: #000000 !important;
      }

      td,
      th {
         padding: 0rem !important;
      }
   }

   .soft {
      color: #000000;
   }

   .odrinv-wrap {
      width: fit-content;
      max-width: 340px;
   }

   .odrinv-head {
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: column;
   }

   .odrinv-total div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // border:2px solid blue
   }

   .odrinv-foot {
      display: flex;
      flex-direction: column;
      align-items: center;
   }
}

#invoice .soft {
   font-size: 0.75rem;
   font-weight: 500;
   line-height: 0.9375rem;
   letter-spacing: 0px;
   // color: #405076;
}

#invoice .strong {
   font-size: 0.625rem;
   font-weight: 600;
   line-height: 1.3125rem;
   letter-spacing: 0px;
   text-align: left;
   // color: #1A264E;
}

#invoice .midfont {
   font-size: 1rem;
   font-weight: 500;
   line-height: 1rem;
   letter-spacing: 0px;
   text-align: right;
}

.kot {
   visibility: hidden;
   display: none;
   position: fixed;
}

@media print {
   body,
   * {
      visibility: hidden;
      font-family: "Fira Code", monospace !important;
   }

   #freshworks-container {
      display: none;
   }

   #SvgjsSvg1001 {
      display: none;
   }

   #invoice,
   #invoice * {
      visibility: visible;
   }

   #printable,
   #printable * {
      visibility: visible;
   }

   #printable {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
   }

   #invoice {
      visibility: visible;
      width: 100%;
      position: fixed;
      top: 0;
      display: flex;
      justify-content: center;
      top: 0;
   }
}
